import { useContext, useEffect, useState } from "react";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
  IVTableSeparator,
} from "./groupDetailsTabTable";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";
import { displayShortDate, displayShortTime } from "pages/events/utils";
import DeleteSubmissionModal from "./deleteSubmissionModal";
import EditCritiqueModal from "./editCritiqueModal";
import MoveToReviewModal from "./moveToReviewModal";
import { on } from "events";

const SubmissionCritiqueStatus = ({ submission }: { submission: any }) => {
  const status = submission.status_for_user;
  const [showEditCritiqueModal, setShowEditCritiqueModal] = useState(false);
  if (status === "Read Critiques") {
    return (
      <a
        className="btn submission green"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Give Critique") {
    if (submission.user_finished_critique) {
      return (
        <>
          <a
            className="submission btn purple"
            href="#"
            role="button"
            onClick={() => setShowEditCritiqueModal(true)}
          >
            Edit Critique
          </a>
          <EditCritiqueModal
            submission={submission}
            show={showEditCritiqueModal}
            onHide={() => {
              setShowEditCritiqueModal(false);
            }}
          />
        </>
      );
    }
    return (
      <a
        className="submission btn blue"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Finish Critique") {
    return (
      <a
        className="submission btn purple"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Submitted") {
    return (
      <a
        className="btn-default submission cutoff"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }

  return <>{submission.status_for_user}</>;
};

type SubmissionInCritique = {
  id: number;
  title: string;
  due_date: string;
  finished_critiques_count: number;
  status_for_user: string;
  can_be_removed: boolean;
  user_finished_critique: boolean;
  author: {
    id: number;
    pen_name: string;
  };
};

const SubmissionInCritiqueTab = ({
  submission,
  onDelete,
  onMoveToReview,
}: {
  submission: SubmissionInCritique;
  onDelete: (submission: SubmissionInCritique) => void;
  onMoveToReview: (submission: SubmissionInCritique) => void;
}) => {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  return (
    <IVTr>
      <IVTd>
        {displayShortDate(submission.due_date)}{" "}
        {displayShortTime(submission.due_date)}
        <br />
        <a role="button" data-target="#myModal5" data-toggle="modal">
          Edit
        </a>
      </IVTd>

      <IVTd>
        <a
          className="cutoff"
          href={`/critique/all_for/${submission.id}/Submission/`}
        >
          {submission.title}
        </a>
      </IVTd>

      <IVTd>
        <a href={`http://localhost:3000/profile/view/${submission.author.id}`}>
          {userState.data.id === submission.author.id
            ? "Me"
            : submission.author.pen_name}
        </a>
      </IVTd>

      <IVTd>
        <SubmissionCritiqueStatus submission={submission} />
      </IVTd>

      <IVTd className="text-center">{submission.finished_critiques_count}</IVTd>

      <IVTd className="text-center">
        {userState.data.id === submission.author.id &&
          submission.can_be_removed && (
            <>
              <div>
                <a href="#" onClick={() => onDelete(submission)}>
                  Remove
                </a>
              </div>
              <div>
                <a href="#" onClick={() => onMoveToReview(submission)}>
                  Move to Review
                </a>
              </div>
            </>
          )}
      </IVTd>
    </IVTr>
  );
};

type GroupDetailCritiqueTabProps = {
  submissions: SubmissionInCritique[] | null;
  onChange?: () => void;
};

export default function GroupDetailCritiqueTab({
  submissions,
  onChange,
}: GroupDetailCritiqueTabProps) {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [submissionToDelete, setSubmissionToDelete] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToMoveToReview, setSubmissionToMoveToReview] =
    useState<SubmissionInCritique | null>(null);

  return (
    <div className="tab-pane active current-tab" id="current">
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col">Due</th>
            <th scope="col">Title</th>
            <th scope="col">Author</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center" style={{ width: "8%" }}>
              Critiques
            </th>
            <th scope="col" className="text-center">
              More
            </th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          {submissions
            ?.filter((submission) => submission.author.id === userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}

          <IVTableSeparator cols={6} />

          {submissions
            ?.filter((submission) => submission.author.id != userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}
        </IVTableBody>
      </IVTable>
      {submissionToDelete && (
        <DeleteSubmissionModal
          submission={submissionToDelete}
          show={true}
          onHide={(deleted) => {
            setSubmissionToDelete(null);
            if (deleted) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToMoveToReview && (
        <MoveToReviewModal
          submission={submissionToMoveToReview}
          show={true}
          onHide={(deleted) => {
            setSubmissionToMoveToReview(null);
            if (deleted) {
              onChange && onChange();
            }
          }}
        />
      )}
    </div>
  );
}
