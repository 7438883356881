import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row, Form } from "react-bootstrap";
import Select from 'react-select';

import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";

import './InviteUserForm.scss';
import { BaseUser } from "types";

interface InviteFormProps {
  groupId: number;
}

const InviteUserForm: React.FC<InviteFormProps> = ({ groupId }) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [state, fetchData] = useFetch();
  const [users, setUsers] = useState<BaseUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string; label: string }[]>([]);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<{ users?: string; message?: string }>({});

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedUsers(selectedOptions);
  };

  useEffect(() => {
    fetchData({
      url: `api/v1.0/user/active/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setUsers(res);
    }).catch((error) => {
      console.error("Error fetching users data:", error);
    });
  }, [authenticationContext.token]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      recipient: selectedUsers.map(String),
      body: message,
    };

    fetchData({
      url: `group/api/invite/user/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        // Reset form or show success message
        setMessage('');
        setSelectedUsers([]);
        toast.success('Invitation sent successfully!');
      })
      .catch((error: any) => {
        if (error.message) {
          const errorData = typeof error.message === 'string' ? JSON.parse(error.message) : error.response.data;
          setErrors(errorData);
          toast.error(errorData.detail);
        } else {
          toast.error('Error submitting form:', error);
        }
      });
  };

  const userOptions = users.map((user: any) => ({
    value: user.id.toString(),
    label: user.pen_name,
  }));

  const DropdownIndicator = () => null;

  return (
    <form onSubmit={handleSubmit} className="ink-invite-form">
      <Row>
        <Col md={6}>
          <h5 style={{ margin: 0 }}>Invite an Inked Voices member</h5>
          <p><em>(separate multiple names with a comma)</em></p>
        </Col>
        <Col md={6}>
          {errors.users && <div className="error">{errors.users}</div>}
          <div className="input-holder">
            <Form.Group controlId="formUsers">
              <Select
                isMulti
                value={selectedUsers}
                onChange={handleSelectChange}
                options={userOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="[Type the user name]"
                components={{ DropdownIndicator }}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}></Col>
        <Col md={6}>
          {errors.message && <div className="error">{errors.message}</div>}
          <div className="textarea-holder">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="[Send a message with the invitation]"
              className="form-control"
            />
          </div>
          <div className="button-holder">
            <button type="submit" className="btn purple">Submit</button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default InviteUserForm;