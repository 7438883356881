import { Tab, Tabs } from "react-bootstrap";
import GroupDetailCritiqueTab from "./groupDetailCritiqueTab";
import GroupDetailReviewTab from "./groupDetailReviewTab";
import { useContext, useEffect, useState } from "react";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { on } from "events";
import GroupDetailsSubmissionsTab from "./groupDetailsSubmissionsTab";
import { GroupSubmissionSchedule } from "types";
import GroupDetailsFilesTab from "./groupDetailFilesTab";

export type SubmissionInCritique = {
  id: number;
  title: string;
  due_date: string;
  finished_critiques_count: number;
  status_for_user: string;
  can_be_removed: boolean;
  user_finished_critique: boolean;
  author: {
    id: number;
    pen_name: string;
  };
};

export default function GroupDetailTabs({ groupId }: { groupId: number }) {
  const authenticationContext = useContext(AuthenticationContext);
  const [submissionsToReview, setSubmissionsToReview] = useState<
    SubmissionInCritique[] | null
  >(null);
  const [submissionsToCritique, setSubmissionsToCritique] = useState<
    SubmissionInCritique[] | null
  >(null);

  const [submissionSchedule, setSubmissionSchedule] =
    useState<GroupSubmissionSchedule | null>(null);

  const fetchSubmissionsToReview = () => {
    const url = `api/v1.0/group/${groupId}/submissions-to-review/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionsToReview(response);
    });
  };

  const fetchSubmissionsToCritique = () => {
    const url = `api/v1.0/group/${groupId}/submissions-to-critique/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionsToCritique(response);
    });
  };

  const fetchSubmissionsSchedule = () => {
    const url = `api/v1.0/group/${groupId}/submission-schedule/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setSubmissionSchedule(response);
    });
  };

  useEffect(() => {
    onChange();
    fetchSubmissionsSchedule();
  }, [groupId]);

  const onChange = () => {
    fetchSubmissionsToReview();
    fetchSubmissionsToCritique();
  };

  return (
    <Tabs defaultValue="critique" className="nav nav-tabs w-full">
      <Tab title="Critique" tabClassName="tab-purple" eventKey="critique">
        <GroupDetailCritiqueTab submissions={submissionsToCritique} />
      </Tab>
      <Tab title="Review" tabClassName="tab-purple" eventKey="review">
        <GroupDetailReviewTab
          submissions={submissionsToReview}
          onChange={onChange}
        />
      </Tab>
      <Tab title="Submissions" tabClassName="tab-purple" eventKey="submissions">
        <GroupDetailsSubmissionsTab
          groupSubmissionSchedule={submissionSchedule}
        />
      </Tab>
      <Tab
        title="Files"
        tabClassName="tab-purple"
        eventKey="files"
        style={{ width: "100%" }}
      >
        <GroupDetailsFilesTab groupId={groupId} />
      </Tab>
    </Tabs>
  );
}
