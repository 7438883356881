import config from "config";
import LeaveGroupModal from "pages/dashboard/components/modals/LeaveGroup";
import { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BaseGroup } from "types";

type GroupAdminOptionsProps = {
  group: BaseGroup;
};

const GroupAdminOptions = ({ group }: GroupAdminOptionsProps) => {
  const [show, setShow] = useState(false);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const target = useRef(null);

  return (
    <>
      <a ref={target} onClick={() => setShow(!show)}>
        <img src={config.BASE_API_URL + "static/img/cog.png"} />
      </a>
      <Overlay target={target.current} show={show} placement="bottom-start">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <ul {...props} className="group-admin-tooltip">
            <li>
              <a
                href={
                  config.BASE_API_URL + "group/notifications/" + group.id + "/"
                }
              >
                Notifications
              </a>
            </li>
            {group.is_admin ? (
              <>
                <li>
                  <a
                    href={config.BASE_API_URL + "group/edit/" + group.id + "/"}
                  >
                    Edit Group
                  </a>
                </li>
                <li>
                  <Link to={"/group/admin/" + group.id + "/"}>
                    Group Admin
                  </Link>
                </li>
                <li>
                  <Link to={"/groups/" + group.id + "/who-critiqued/"}>
                    Critique Summary
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowLeaveGroupModal(true);
                    }}
                  >
                    Leave Group
                  </a>
                </li>
              </>
            )}
          </ul>
        )}
      </Overlay>
      <LeaveGroupModal
        group={group}
        show={showLeaveGroupModal}
        onHide={() => setShowLeaveGroupModal(false)}
      />
    </>
  );
};

export default GroupAdminOptions;
