import React, { useEffect, useContext, useState } from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from 'react-select';

import useFetch from "custom-hooks/useFetch";
import AuthenticationContext from "contexts/AuthenticationContext";

import config from "../../config";
import { BaseUser } from "types";

const BASE_API_URL = config.BASE_API_URL;

interface TransferGroupModalProps {
  show: boolean;
  handleClose: () => void;
  groupId: number;
  groupName: string;
}

const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const TransferGroupModal: React.FC<TransferGroupModalProps> = ({ show, handleClose, groupId, groupName }) => {
  const authenticationContext = useContext(AuthenticationContext);
  const [state, fetchData] = useFetch();
  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState<{ value: string; label: string } | null>(null);

  useEffect(() => {
    fetchData({
      url: `api/v1.0/group/${groupId}/members/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((res) => {
      setOwners(res);
    });
  }, [fetchData, authenticationContext.token]);

  const handleSubmit = async () => {
    if (!selectedOwner) {
      toast.error('Please select a user to transfer ownership to.');
      return;
    }

    const data = {
      owner: selectedOwner.value,
      groupId,
    };

    fetchData({
      url: `group/api/transfer-group-ownership/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        setSelectedOwner(null);
        toast.success(response.message);
        // Wait 2 seconds before redirecting to group page
        setTimeout(() => {
          window.location.href = `${BASE_API_URL}group/${groupId}/`;
        }, 2000);
      })
      .catch((error: any) => {
        if (error) {
          toast.error(error);
        } else {
          console.error('Error submitting form:', error);
        }
      });
  };

  const ownerOptions = owners.map((owner: BaseUser) => ({
    value: owner.id.toString(),
    label: owner.pen_name,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Group Ownership</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Who would you like to transfer group ownership to?</p>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} style={{ paddingBottom: '3%' }}>
              <Form.Group controlId="formOwners">
                <Form.Label>Owners</Form.Label>
                <Select
                  value={selectedOwner}
                  onChange={setSelectedOwner}
                  options={ownerOptions}
                  className="basic-single-select"
                  classNamePrefix="select"
                  placeholder="[Select new owner]"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Button type="submit" className="btn">
                Transfer
              </Button>
              <Button variant="secondary" onClick={handleClose} className="btn cancel">
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TransferGroupModal;