import AuthenticationContext from "contexts/AuthenticationContext";
import { createRef, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import sendRequest from "services/dataService";
import { GroupSubmissionSchedule } from "types";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTableSeparator,
  IVTd,
  IVTr,
} from "./groupDetailsTabTable";

type GroupDetailsFilesTabProps = {
  groupId: number;
};

export default function GroupDetailsFilesTab({
  groupId,
}: GroupDetailsFilesTabProps) {
  const inputRef = createRef<HTMLInputElement>();
  const authenticationContext = useContext(AuthenticationContext);
  const [groupFiles, setGroupFiles] = useState<any[]>([]);

  const handleFileUpload = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const expectedExtension = ["docx", "pdf", "png"];
    const maxFileSize = 15728640;
    if (!expectedExtension.includes(file.name.split(".").at(-1))) {
      alert("Unexpected file format");
    }
    if (file.size > maxFileSize) {
      alert("File size exceeds 15 MB");
    }
  };

  const fetchGroupFiles = (groupId: number) => {
    const url = `api/v1.0/group/${groupId}/files/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setGroupFiles(response.results);
    });
  };

  useEffect(() => {
    fetchGroupFiles(groupId);
  }, [groupId]);

  return (
    <>
      <Row className="w-full">
        <Col sm={12} md={10}>
          <p className="text-sm mt-2">
            Share your manuscript synopsis, helpful resources, conference notes,
            illustrations, etc. here. <br /> This section is for file-sharing
            but not critique. Accepted formats: .docx, .pdf, .png.
          </p>
        </Col>
        <Col sm={12} md={2}>
          <a
            className="btn green text-sm float-right mt-2 btn-small"
            onClick={() => inputRef.current?.click()}
          >
            Upload a file
          </a>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={handleFileUpload}
          />
        </Col>
      </Row>
      <Row>
        <IVTable>
          <IVTableHead>
            <IVTr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Owner</th>
              <th scope="col" style={{ width: "10%" }}></th>
            </IVTr>
          </IVTableHead>
          <IVTableBody>
            {groupFiles?.map((file: any) => (
              <IVTr>
                <IVTd>{file.upload_date}</IVTd>
                <IVTd>{file.name}</IVTd>
                <IVTd>{file.owner.pen_name}</IVTd>
                <IVTd>
                  <a>Delete</a>
                </IVTd>
              </IVTr>
            ))}
          </IVTableBody>
        </IVTable>
      </Row>
    </>
  );
}
